import Swiper from 'swiper/bundle';
export default {
  init() {
    $('#status').fadeOut(); // will first fade out the loading animation 
    $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 

    const post_type = window.sage.page_template;
    if(post_type == 'products' || post_type == 'winery' || post_type == 'post' || post_type == 'page') {
      if ($(window).width() > 500) {
        $('h1').responsiveHeadlines({
          container: 'page-header',
          maxFontSize: 64,
          minFontSize: 18,
        });
      }
    
    }
    var topofDiv = $('#header, #header-page').offset().top;

    var height = $('#header, #header-page').outerHeight();
    $('body').scroll(function(){
      if($('body').scrollTop() > (topofDiv + height) -300){
        $('.head').addClass('sticky-bkg');
        if ($(window).width() <= 820) {
          $('.brand img').attr('src', window.sage.template_directory + '/assets/images/logo.svg');
          $('.brand img').css( 'width', '80%' );
        } else {
          $('.brand img').attr('src', window.sage.template_directory + '/assets/images/logo-colour.svg');
          $('.brand img').css( 'width', '50%' );
        }
        $('.head .container').removeClass('mt-3');
      }
      else{
        $('.head').removeClass('sticky-bkg');
        $('.brand img').attr('src', window.sage.template_directory + '/assets/images/logo.svg');
        $('.brand img').css( 'width', 'auto' );
        $('.head .container').addClass('mt-3');
      }
    });
    // JavaScript to be fired on all pages
    new Swiper('.products', {
      slidesPerView: 5,
      spaceBetween: 0,
      autoplay: true,
      loop: false,
      navigation: {
        nextEl: '#case-next',
        prevEl: '#case-back',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        1080: {
          slidesPerView: 5,
          spaceBetween: 60,
        },
      },
    });

    new Swiper('.products-winery', {
      slidesPerView: 3,
      spaceBetween: 60,
      autoplay: true,
      loop: false,
      navigation: {
        nextEl: '#wine-next',
        prevEl: '#wine-back',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        1080: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
      },
    });

    new Swiper('.news', {
      slidesPerView: 2,
      spaceBetween: 30,
      autoplay: false,
      loop: true,
      navigation: {
        nextEl: '#news-next',
        prevEl: '#news-back',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        1080: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    });
    
    /*
    $('.bar-menu').click(function() {
      $('.modal-nav').addClass('menu-active');
      $('.brand img').toggleClass('animate__animated animate__pulse');
      $('.head').toggleClass('menu-header');
      $('.btn-white').toggleClass('animate__animated animate__pulse');
    });
    */

    $('.bar-menu').click(function() {
      $(this).toggleClass('active');
      $('.bar-menu-text').toggleClass('d-none');
      $('.modal-nav').addClass('menu-active');
      $('.modal-nav').toggleClass('animate__animated animate__zoomIn animate__faster');
      $('.modal-nav').toggleClass('animate__animated animate__zoomOut animate__faster');
      if (!$(this).hasClass('active')){

        setTimeout(function() {
          $('.modal-nav').removeClass('menu-active');
          $('.bar-menu-text').removeClass('d-none');
        }, 500);

      }   
      $('.brand img').toggleClass('animate__animated animate__pulse');
      $('.head').toggleClass('menu-header');
      $('.btn-white').toggleClass('animate__animated animate__pulse');
    });
  },
  finalize() {
  },
};
